import * as React from "react";
// import * as Location from 'expo-location';
import { Component } from "react";
import {
  // Text,
  // Button,
  // Clipboard,
  View,
  SafeAreaView,
  ScrollView,
} from "react-native";

import styles from "../../utils/styles";

import RestaurantTile from "../../elements/RestaurantTile";
import DistanceSlider from "../../elements/DistanceSlider";
import CartEstimateList from "../../elements/CartEstimateList";

export default class RestaurantPageScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurantInfo: undefined,
      cart_estimate: 20, // Cart estimation for price calculator. 0 - 100.
    };
  }

  componentDidMount() {
    console.log("RestaurantPageScreen Mounted");
    console.log(
      "RestaurantPageScreen PlaceID: " +
        this.props.route.params.restaurantInfo.place_id
    );
    this.setState({ restaurantInfo: this.props.route.params.restaurantInfo });
  }

  render() {
    if (!this.state.restaurantInfo) {
      return <View></View>;
    } else {
      return (
        <SafeAreaView style={styles.SafeAreaViewStyle}>
          <ScrollView style={[styles.ScrollViewStyle, {paddingHorizontal: 10}]}>
            <View style={{ paddingTop: 10 }} />

            <View style={styles.RestaurantTileListView} key={"Restaurant Tile"}>
              <View style={styles.RestaurantTileListView}>
                <RestaurantTile
                  restaurantInfo={this.state.restaurantInfo}
                  services={this.state.restaurantInfo.services}
                  services_updated_at={
                    this.state.restaurantInfo.services_updated_at
                  }
                />
              </View>
            </View>
            <View style={{ paddingTop: 10 }} />

            <View
              style={styles.RestaurantTileListView}
              key={"Restaurant Cart Estimation Slider"}
            >
              <DistanceSlider
                value={this.state.cart_estimate}
                minimumValue={0}
                maximumValue={100}
                title={"Total Cart Estimation"}
                units={"$"}
                step={5}
                onValueChange={(new_estimate) => {
                  this.setState({ cart_estimate: new_estimate });
                }}
              />
            </View>

            <View
              style={styles.RestaurantTileListView}
              key={"Restaurant Cart Est Calculator"}
            >
              <CartEstimateList
                services={this.state.restaurantInfo.services}
                cart_estimate={this.state.cart_estimate}
              />
            </View>
          </ScrollView>
        </SafeAreaView>
      );
    }
  }
}
